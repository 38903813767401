<template>
  <div role="group" class="form-group form-row h-100">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) }}</label>
    <div :class="settings.classes.input" class="h-100">
      <div v-if="state" class="text-left bg-gray-900">
        <template v-for="(button, index) in editorButtons">
          <span v-if="button.type === 'divider'" class="divider" :key="`divider${index}`" />
          <MenuItem v-else :key="index" :state="state" v-bind="button" />
          <input v-if="button.action === 'setImage'" ref="input_file" type="file" class="input-file" :key="`image${index}`" multiple="false" accept="image/x-png,image/jpeg" @change="onInputFile" @input="onInputFile" />
        </template>
      </div>
      <div class="drop-zone" @drop="onDrop($event, 1)" @paste.prevent="onPaste" @dragover.prevent @dragenter.prevent>
        <EditorContent ref="editor_content" class="form-control h-100" :class="{ 'is-invalid': !isValid }" :editor="state" :name="name" :placeholder="$t(c_placeholder)" v-bind="$attrs" v-on="listeners" />
      </div>
      <template v-if="!isValid">
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback" style="display: block;">{{ error.message }}</div>
      </template>
    </div>

    <div v-if="cropper.media">
      <ACropper :id="cropper.type" :media="cropper.media" :options="cropper.options" @imageCropped="onImageCropped" />
    </div>
  </div>
</template>

<script>
const CONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

import filesMixin from '@/app/views/_mixins/files-mixin'
import * as imageUtils from '@/app/_utils/image-utils'

/* https://tiptap.dev/ */
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import HighLight from '@tiptap/extension-highlight'
import Document from '@tiptap/extension-document'
import Heading from '@tiptap/extension-heading'
import ImageExt from '@tiptap/extension-image'
import Text from '@tiptap/extension-text'
import Link from '@tiptap/extension-link'

import MenuItem from './MenuItem.vue'

export default {
  name: 'TiptapEditor',
  inheritAttrs: true,
  mixins: [filesMixin],
  components: {
    MenuItem,
    EditorContent
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.state.getHTML() === value
      // JSON
      // const isSame = this.state.getJSON().toString() === value.toString()
      if (isSame) return
      this.state.commands.setContent(value, false)
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    helper: {
      type: String
    },
    placeholder: {
      type: String
    },

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    name_clean() {
      return this.name.split('.')[0]
    },
    listeners() {
      const { input, change, ...listeners } = this.$listeners
      return listeners
      /*return {
        ...this.$listeners,
        input: event => this.$emit('input', event.value)
        //change: event => this.$emit('change', event),
        //update: event => this.$emit('update:value', event)
      }*/
    }
  },
  data() {
    return {
      //file_data: '',
      state: new Editor({
        content: this.modelValue,
        injectCss: false,
        //editable: true,
        //extensions: [StarterKit],

        extensions: [
          Text,
          Link.configure({ openOnClick: false }),
          ImageExt.configure({ inline: true, allowBase64: true }), //, HTMLAttributes: { style: 'max-width: 100%;' } }),
          Heading,
          Document,
          Underline,
          HighLight,
          Paragraph,
          StarterKit,
          TextAlign.configure({
            types: ['heading', 'paragraph']
          })
        ],
        onUpdate: e => {
          //console.log('onUpdate')
          // HTML
          /*this.$emit('update:modelValue', this.state.getHTML())*/
          this.$emit('input', this.state.getHTML(), e)

          // JSON
          // this.$emit('update:modelValue', this.state.getJSON())
        }
      }),
      settings: { ...CONFIG },
      c_placeholder: this.$attrs.placeholder || this.placeholder || this.label,

      images: { current: {}, temp: {} },
      cropper: { file: '', type: '', modal: false, media: {}, options: {} },

      editorButtons: [
        //
        { icon: 'bold', name: 'Bold', action: 'toggleBold', active: 'bold' },
        { icon: 'italic', name: 'Italic', action: 'toggleItalic', active: 'italic' },
        { icon: 'underline', name: 'Underline', action: 'toggleUnderline', active: 'underline' },
        { icon: 'strikethrough', name: 'Strike', action: 'toggleStrike', active: 'strike' },
        { type: 'divider' },
        { icon: 'code-view', name: 'Code', action: 'toggleCode', active: 'code' },
        { icon: 'mark-pen-line', name: 'HighLight', action: 'toggleHighlight', active: 'highlight' },
        //{ icon: 'paragraph', name: 'Paragraph', action: 'setParagraph' },
        //{ icon: 'h-1', name: 'H1', action: 'toggleHeading', active: 'heading', params: { level: 1 } },
        //{ icon: 'h-2', name: 'H2', action: 'toggleHeading', active: 'heading', params: { level: 2 } },
        { icon: 'h-1', name: 'H1', action: 'toggleHeading', active: 'heading', params: { level: 3 } }, // H3
        { icon: 'h-2', name: 'H2', action: 'toggleHeading', active: 'heading', params: { level: 4 } }, // H4
        { icon: 'h-3', name: 'H3', action: 'toggleHeading', active: 'heading', params: { level: 5 } }, // H5
        //{ icon: 'h-6', name: 'H6', action: 'toggleHeading', active: 'heading', params: { level: 6 } },
        { icon: 'list-unordered', name: 'Bullet list', action: 'toggleBulletList', active: 'bulletList' },
        { icon: 'list-ordered', name: 'Ordered list', action: 'toggleOrderedList', active: 'orderedList' },
        //{ icon: 'list-check-2', name: 'Task list', action: 'toggleTaskList', active: 'taskList' },
        //{ icon: 'code-box-line', name: 'Code block', action: 'toggleCodeBlock', active: 'codeBlock' },
        { icon: 'image-add-line', name: 'Add image', action: 'setImage', params: { src: this.url } },

        { type: 'divider' },
        { icon: 'link', name: 'Add link', action: 'setLink' },
        { icon: 'link-unlink', name: 'Remove link', action: 'unsetLink' },
        { type: 'divider' },
        //{ icon: 'double-quotes-l', name: 'Blockquote', action: 'toggleBlockquote', active: 'blockquote' },
        //{ icon: 'separator', name: 'Horizontal rule', action: 'setHorizontalRule' },
        //{ type: 'divider' },
        //{ icon: 'text-wrap', name: 'Hard break', action: 'setHardBreak' },
        //{ icon: 'format-clear', name: 'Clear marks', action: 'unsetAllMarks' },
        //{ icon: 'format-clear', name: 'Clear nodes', action: 'clearNodes' },
        //{ type: 'divider' },
        { icon: 'align-left', name: 'Align Left', action: 'setTextAlign', active: 'textAlign', params: 'left' },
        { icon: 'align-center', name: 'Align Center', action: 'setTextAlign', active: 'textAlign', params: 'center' },
        { icon: 'align-right', name: 'Align Right', action: 'setTextAlign', active: 'textAlign', params: 'right' },
        { icon: 'align-justify', name: 'Justify', action: 'setTextAlign', active: 'textAlign', params: 'justify' },
        { type: 'divider' },
        { icon: 'format-clear', name: 'Clear format', action: 'clear' },
        { type: 'divider' },
        { icon: 'arrow-go-back-line', name: 'Undo', action: 'undo' },
        { icon: 'arrow-go-forward-line', name: 'Redo', action: 'redo' }
      ]
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
  },
  beforeDestroy() {
    this.state.destroy()
  },
  methods: {
    upload(e) {
      console.log('UPLOAD', e)
    },
    onInputFile(evt) {
      this.handleFileSelected('none', evt.target.files, evt, { aspectRatio: false, folder: 'none' })
    },
    onPaste(evt, callback) {
      // TODO: control original paste
      evt.preventDefault()
      const items = evt.clipboardData.items
      if (items === undefined) {
        if (typeof callback == 'function') {
          callback(undefined)
          console.log('Undefined 2')
        }
      }
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') == -1) continue
        const image = items[i].getAsFile()
        evt.target.files = [image]
        this.onInputFile(evt)
      }
    },
    onDrop(evt) {
      evt.preventDefault()
      for (const file of evt.dataTransfer.files) {
        this.onInputFile({ target: { files: [file] } }) // TODO: inject and move to the next node
      }
    },

    // Override Cropper (file-utils) onImageCropped
    onImageCropped(media) {
      if (!media.cropped) return

      const self = this
      const reader = new FileReader()
      reader.onload = e => {
        const image = new Image()
        image.src = e.target.result
        image.onload = function() {
          self.state
            .chain()
            .focus()
            .setImage({ src: imageUtils.resizeImage(image, this.width, this.height) })
            .run()
        }
      }
      reader.readAsDataURL(media.blob) // TODO: if not cropped?
    }
  }
}
</script>

<style lang="scss">
/* Basic editor styles */

.drop-zone {
  overflow: auto;
  max-height: calc(100vh - 500px);
}

.input-file {
  width: 34px;
  opacity: 0;
  margin-left: -38px;
  margin-right: 4px;
}

.divider {
  width: 1px;
  border: 1px solid lightgray;
  margin-right: 0.25rem;
  vertical-align: middle;
}

.ProseMirror-focused:focus-visible {
  outline: none;
}

.ProseMirror {
  > * + * {
    border: none;
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
