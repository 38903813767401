/* eslint-disable @typescript-eslint/no-explicit-any */

export const resizeImage = (image: any, width: number, height: number, maxWidth?: number, maxHeight?: number) => {
  const MAX_WIDTH = maxWidth || 500
  const MAX_HEIGHT = maxHeight || 500
  const canvas = document.createElement('canvas')
  const context1 = canvas.getContext('2d') || { drawImage: () => {} }
  context1.drawImage(image, 0, 0)

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width
      width = MAX_WIDTH
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height
      height = MAX_HEIGHT
    }
  }

  canvas.width = width
  canvas.height = height
  const context2 = canvas.getContext('2d') || { drawImage: () => {} }
  context2.drawImage(image, 0, 0, width, height)

  return canvas.toDataURL('image/png') // TODO: other types ?
}
