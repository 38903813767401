<template>
  <button class="btn btn-sm btn-editor" :class="{ 'btn-primary': isActive(), 'btn-secondary': !isActive() }" :title="name" @click.prevent="runAction">
    <svg class="remix">
      <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
    </svg>
  </button>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

export default {
  props: {
    state: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    active: String,
    params: {
      type: [String, Object]
    }
  },

  data() {
    return {
      remixiconUrl
    }
  },
  methods: {
    isActive() {
      return this.active && this.state.isActive(this.active, this.params)
    },

    runAction() {
      let params = this.params
      if (this.action === 'setImage') {
        const src = window.prompt('URL')
        if (src) params = { src }
        else return
      }

      console.log(this.action)

      if (this.action === 'clear') return this.clear()
      if (this.action === 'setLink') return this.setLink()

      this.state
        .chain()
        .focus()
        [this.action](params)
        .run()
    },

    // Specific Methods
    clear() {
      this.state
        .chain()
        .focus()
        .clearNodes()
        .unsetAllMarks()
        .run()
    },
    setLink() {
      console.log('ADD LINK')

      const previousUrl = this.state.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) return

      // empty
      if (url === '') {
        this.state
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
        return
      }

      // update link
      this.state
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: '_blank' }) // TODO: Check if _blank ?
        .run()
    }
  }
}
</script>

<style lang="scss">
.btn-editor {
  margin-right: 0.25rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  &.is-active,
  &:hover {
    color: #fff;
  }
}
</style>
